<template>
  <div>
    <component
      :is="search_component"
      v-if="relationship && params"
      v-bind="Object.assign({}, $attrs)"
      v-on="$listeners"
      :default_per_page="default_per_page_"
      :_index_params="index_params"
      :stateName="relationship['from']['relation_key'] + '_' + relation_alias + '_pagination_select' + (stateIdentifier ? '_' + stateIdentifier : '')"
      :fields="fields"
      :class_name="relationship['to']['class_name']"
      :allow_bottom_pagination="false"
      :show_relationship_actions="false"
      @rowClicked="rowClicked"
      @rowHovered="rowHovered"
      @rowUnhovered="rowUnhovered"
    >
      <!-- Pass down slots in a wrapper component -->
      <!-- ref: https://stackoverflow.com/a/50892881/3553367 -->
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>

      <template v-slot:select_column_slot="row">
        <b-button-toolbar class="table_actions" :class="$mq == 'xs' ? 'float-right' : 'float-left'">
          <b-button-group size="sm">
            <!-- TODO: for speed's sake, allow adding when still loading, however it should update to the latest available data, but somehow should not override user input -->
            <!-- :disabled="loading || selected_items && (selected_items.find(selected_item => selected_item.id == row.item.id)) ? true  : false" -->
            <b-button
              :disabled="selected_items && selected_items.find(selected_item => selected_item.id == row.item.id) ? true : false"
              @click="addItem(row.item)"
              variant="success"
            >
              <i class="fa fa-plus"></i>
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </template>

      <!-- <template v-slot:search_bottom_slot="{}">
        <div
          v-if="relationshipRules && relationshipRules['default'] && (relationshipRules['default']['max'] != 'infinite' && selected_items.length > relationshipRules['default']['max'])"
          class="alert alert-danger"
          role="alert"
        >Only {{relationshipRules['default']['max']}} {{pluralize('item', relationshipRules['default']['max'])}} can be selected, {{selected_items.length}} selected</div>
      </template>-->

      <template v-slot:search_bottom_row_slot="search_bottom_row_slot_props">
        <b-col
          cols="12"
          :sm="'6'"
          class="my-1"
          v-if="allow_select_all && (relationshipRules && relationshipRules['default'] && (relationshipRules['default']['max'] == 'infinite' || relationshipRules['default']['max'] > 1))"
        >
          <b-button-group size="sm">
            <b-button
              variant="success"
              @click="addAll(search_bottom_row_slot_props.pagination.data)"
              :disabled="!search_bottom_row_slot_props.pagination.data || search_bottom_row_slot_props.pagination.length <= 0"
            >Select All</b-button>
          </b-button-group>
        </b-col>
      </template>
    </component>
    <div
      v-if="relationshipRules && relationshipRules['default'] && (relationshipRules['default']['max'] != 'infinite' && selected_items.length > relationshipRules['default']['max'])"
      class="alert alert-danger"
      role="alert"
    >Only {{relationshipRules['default']['max']}} {{pluralize('item', relationshipRules['default']['max'])}} can be selected, {{selected_items.length}} selected</div>
  </div>
</template>
<script>
import _ from 'lodash';
import { Switch as cSwitch } from '@coreui/vue';
import Search from '../../Search';
import pluralize from 'pluralize';

export default {
  components: {
    cSwitch,
  },
  props: {
    _search_component: {},
    relationship: {
      type: Object,
      default: null,
    },
    params: {
      type: Object,
      required: true,
    },
    default_per_page: {
      type: Number,
    },
    enable_maxed_error: {
      type: Boolean,
      default: true,
    },
    stateIdentifier: {
      type: String,
      default: '',
    },
    _index_params: {
      type: Object,
    },
    _exclude_fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _include_property_keys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    except_virtual_property_keys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _fields: {
      type: Array,
    },
    allow_select_all: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    search_component() {
      return this._search_component || Search;
    },
    introspect() {
      return this.$d.introspect;
    },
    relation_alias() {
      return this.$d.getRelationAlias(this.relationship);
    },
    exclude_fields() {
      return this._exclude_fields;
      /* return [
        {
          key: 'select',
        },
      ]; */
    },
    include_property_keys() {
      return this._include_property_keys;
      /* return [
        {
          key: 'select',
        },
      ]; */
    },
    fields() {
      let extra_fields = [];
      extra_fields.push({ key: 'select', _slot_name: 'select_column_slot', label: '', tdClass: ['no_break'] });

      let fields = this._fields;
      if (_.isNil(fields)) {
        fields = this.$d.getClassBTableFields(this.relationship['to']['class_name']);
      }

      fields = extra_fields.concat(fields);

      //TODO: repeated across Search.vue, PendingTable.vue & SelectingSearch.vue
      fields = _.map(fields, field => {
        if (field.property) {
          let property = field.property;
          if (property['type'] == 'number') {
            if (typeof field.tdClass === 'string') {
              field.tdClass = [field.tdClass]; //convert to Array
            }
            if (!Array.isArray(field.tdClass)) {
              field.tdClass = []; //make sure it is array
            }

            field.tdClass.push('text-right');
          }
        }
        return field;
      });

      this.exclude_fields.forEach(exclude_field => {
        fields = _.reject(fields, exclude_field);
      });

      if(this.include_property_keys && this.include_property_keys.length > 0){
        fields = fields.filter(field => {
          let keep = false;
          console.log('fieldssss', field)
          if(field.property){
            this.include_property_keys.forEach(include_property_key => {
              keep = field.property['property_key'] == include_property_key
            });
          }else{
            keep = true
          }
          return keep;
        });
      }


      return fields;
    },
    to_class() {
      return this.$d.getClass(this.relationship['to']['class_name']);
    },
    default_per_page_() {
      if (_.isNil(this.default_per_page)) {
        return _.get(this.to_class, ['frontend', 'select', 'default_per_page'], 5);
      }
      return 5;
    },
    index_params() {
      let index_params = {};

      let with_properties = [];

      with_properties = with_properties.concat(this.$d.withVirtualPropertiesByRelationship(this.params, this.relationship));

      if (this.except_virtual_property_keys) {
        with_properties = _.reject(with_properties, with_virtual_property => {
          return this.except_virtual_property_keys.includes(with_virtual_property['property_key']);
        });
      }

      console.log('withVirtualPropertiesByRelationship', with_properties);

      if (with_properties && with_properties.length > 0) {
        let temp_index_params = {};
        temp_index_params['with_properties'] = with_properties;
        index_params = _.merge(index_params, temp_index_params);
      }

      //GAHHHHH
      index_params = _.merge(index_params, this.$d.getRelationToSearch(this.relationship, this.params, null, 'to'));

      if (this._index_params) {
        index_params = _.merge(index_params, this._index_params);
      }

      return index_params;
    },
    selected_items() {
      return this.params[this.relation_alias];
    },
    relationshipRules() {
      //TODO: depreciate this
      console.log(this.relationship['to']['rules']);
      return Object.assign({}, this.relationship['to']['rules']);
    },
  },
  watch: {},
  created() {
    this._ = _;
    this.pluralize = pluralize;
    this.init();
  },
  methods: {
    init() {},
    rowClicked(item) {
      this.$emit('rowClicked', item);
    },
    rowHovered(item) {
      this.$emit('rowHovered', item);
    },
    rowUnhovered(item) {
      this.$emit('rowUnhovered', item);
    },
    addItem(add_item) {
      let item = Object.assign({}, add_item); //avoid object propagation
      console.log('addItem', item, this.params, this.relation_alias);
      let relationship = this.relationship;

      // this.params[this.relation_alias].unshift(item); //put first
      // let index = 0

      this.params[this.relation_alias].push(item); //put last //todo if using this, need to fix the index
      let index = this.params[this.relation_alias].length - 1;

      this.$set(this.params[this.relation_alias][index], relationship['relationship_key'], {}); //initialise properties of a relationship
      // this.params[this.relation_alias][index][relationship['relationship_key']] = {}; //initialise properties of a relationship

      relationship['properties'].forEach(property => {
        let default_value = null;
        if (property['default_using']) {
          default_value = item[property['default_using']];
          // this.params[this.relation_alias][index][relationship['relationship_key']][property['column_name']] = item[property['default_using']];
        } else {
          // default_value = 0
          // this.params[this.relation_alias][index][relationship['relationship_key']][property['column_name']] = 0;
        }
        this.$set(this.params[this.relation_alias][index][relationship['relationship_key']], property['column_name'], default_value);
      });

      // this.params[this.relation_alias].push(item);
      // this.params[this.relation_alias].unshift(item);

      this.params[this.relation_alias] = _.uniqBy(this.params[this.relation_alias], 'id');
      this.$emit('update:params', this.params);
      this.$emit('selected', item);
    },
    addAll(items) {
      items.forEach(item => {
        this.addItem(item);
      });
    },
  },
};
</script>
